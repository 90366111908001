
.sk-chase-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
  }

.sk-chase {
    width: 40px;
    height: 40px;
    position: fixed;
    animation: sk-chase 2.5s infinite linear both;
    z-index: 10;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

  ////// FADE IN TOP //////

  @keyframes fadeInTop {
    0% {
      opacity: 0;
      transform: translateY(100px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }

  @keyframes fadeInLongTop {
    0% {
      opacity: 0;
      transform: translateY(300px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }
  
  .utils__fade-in-fast-top,
  .utils__fade-in-top {
    opacity: 0;
    transition: fadeInTop 1s ease-in both;
  }

  .utils__fade-in-top.appear {
    animation: fadeInTop 1s ease-in-out both 1.25s; 
  }

  .utils__fade-in-fast-top.appear {
    animation: fadeInTop 1s ease-in-out both; 
  }

  /////////////////////////////////////////////////

  ////// FADE IN CENTER //////

  @keyframes fadeInCenter {
    0% {
      opacity: 0;
      transform: scale(0.2);
    } 100% {
      opacity: 1;
      transform: scale(1);
      
    } 
  }

  @keyframes fadeInLongCenter {
    0% {
      opacity: 0;
      transform: scale(0.2);
    } 100% {
      opacity: 1;
      transform: scale(1);
      
    } 
  }
  
  .utils__fade-in-fast-center,
  .utils__fade-in-center {
    opacity: 0;
    transition: fadeInCenter 1s ease-in both;
  }

  .utils__fade-in-center.appear {
    animation: fadeInCenter 1s ease-in-out both 1.25s; 
  }

  .utils__fade-in-fast-center.appear {
    animation: fadeInCenter 1s ease-in-out both; 
  }

  ///////////////////////////////////////////////// 

  ////// FADE IN RIGHT //////

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    } 100% {
      opacity: 1;
      transform: translateX(0px);
      
    } 
  }

  @keyframes fadeInLongRight {
    0% {
      opacity: 0;
      transform: translateX(-300px);
    } 100% {
      opacity: 1;
      transform: translateX(0px);
      
    } 
  }
  
  .utils__fade-in-fast-right,
  .utils__fade-in-right {
    opacity: 0;
    transition: fadeInRight 1s ease-in both;
  }

  .utils__fade-in-right.appear {
    animation: fadeInRight 1s ease-in-out both 1.25s; 
  }

  .utils__fade-in-fast-right.appear {
    animation: fadeInRight 1s ease-in-out both; 
  }

  /////////////////////////////////////////////////
    
    ////// FADE IN LEFT //////

    @keyframes fadeInLeft {
      0% {
        opacity: 0;
        transform: translateX(100px);
      } 100% {
        opacity: 1;
        transform: translateX(0px);
        
      } 
    }
  
    @keyframes fadeInLongLeft {
      0% {
        opacity: 0;
        transform: translateX(300px);
      } 100% {
        opacity: 1;
        transform: translateX(0px);
        
      } 
    }
    
    .utils__fade-in-fast-left,
    .utils__fade-in-left {
      opacity: 0;
      transition: fadeInLeft 1s ease-in both;
    }
  
    .utils__fade-in-left.appear {
      animation: fadeInLeft 1s ease-in-out both 1.25s; 
    }
  
    .utils__fade-in-fast-left.appear {
      animation: fadeInLeft 1s ease-in-out both; 
    }
  
    /////////////////////////////////////////////////